html, body {
  margin: 0;
  // !important overrides strange material-UI behaviour while opening dropdowns
  padding: 0 !important;
  overflow-x: hidden;
  background: #fafafa;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance:textfield;
}
